import LinkingConfiguration from "./LinkingConfiguration";
import { navigationRef } from "@/helpers/navigation";
import { useAppTheme } from "@/hooks";
import DeviceEventEmitter from "@/Lib/DeviceEmitter";
import { appUiStore } from "@/Lib";
import { logScreenView } from "@/Lib/analytics";
import { DarkTheme, NavigationContainer } from "@react-navigation/native";
import { useEffect, useRef } from "react";
import { StatusBar } from "react-native";
import FastImage from "react-native-fast-image";

function Navigation({ children }: { children: React.ReactNode }) {
  const [theme, colors] = useAppTheme();
  const routeNameRef = useRef<string | null>(null);
  const themeColors = {
    dark: theme === "dark",
    colors: {
      primary: colors.primary,
      background: colors.bg,
      card: colors.bg,
      text: colors.color,
      border: colors.secondary_75,
      notification: DarkTheme.colors.notification,
    },
  };

  useEffect(() => {
    DeviceEventEmitter.on("navigate", ([name, params]) => {
      setTimeout(() => {
        navigationRef.navigate(name, params);
      }, 10);
    });

    return () => {
      DeviceEventEmitter.removeAllListeners("navigate");
    };
  }, []);

  return (
    <>
      <StatusBar
        translucent
        barStyle={theme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colors.bg}
      />
      <NavigationContainer
        onReady={() => {
          routeNameRef.current =
            navigationRef.current?.getCurrentRoute()?.name || null;
        }}
        onStateChange={() => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName =
            navigationRef.current?.getCurrentRoute()?.name;
          if (previousRouteName !== currentRouteName) {
            FastImage.clearMemoryCache();
            currentRouteName && logScreenView(currentRouteName);
            appUiStore.setCurrentRoute(currentRouteName);
          }
          routeNameRef.current = currentRouteName || null;
        }}
        ref={navigationRef}
        linking={LinkingConfiguration}
        theme={themeColors}
      >
        {children}
      </NavigationContainer>
    </>
  );
}

export default Navigation;
