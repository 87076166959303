import { logout } from "./rootStore";
import { appUiStore } from "./AppUi";
import { api, mobileApi, mobileApiV3 } from "../api";
import { Tapi } from "../websocket";
import { logLogin, setCustomUserAttribute } from "../analytics";
import {
  StatusIDs,
  StatusNames,
  UserStatus,
  USER_STATUSES,
  USER_STATUSES_MAP,
} from "../const";
import { checkIfMT, decodeToken, MT_TYPE, refreshAppTokens } from "../helpers";
import { storage } from "../storage";
import { TradingAccount } from "@/Features/Profile/Deposits/screens/DepositWithPraxis/types";
import { makeAutoObservable, runInAction } from "mobx";
import { captureException } from "@sentry/react-native";

export interface MobileOfficeAccountInfo {
  fname: "";
  mname: "";
  lname: "";
  registration_dt: "2022-10-29 10:05:52.917";
  registration_ts: "1667037952";
  country_id: "229";
  tel1: "";
  tel1_country_code: "+971";
  tel1_provider_code: "0";
  login: "demo";
  email: "demo@amana.app";
  currency: "USD";
  company: "";
  customer_no: "CU00001";
  countryName: string;
  city: "";
  address: "";
  province: "";
  zip: "";
  tel_pass: "";
  assign_to: "";
  sales_status: "";
  customer_rating: "0.00";
  image_portrait: "";
  status_id: StatusIDs;
  account_status: StatusNames;
  nationality_ext: string;
  nationality?: string;
  is_ib: "0";
  tag: "";
  extended_fields: Record<string, unknown>;
  introducer: "";
  is_dev_mode: false;
  external_service_user_data: unknown[];
  mt_preference: string;
  is_high_leverage: boolean;
  lead_method: string;
  is_dave_floyd_accepted: boolean;
  is_dave_floyd_campaign: boolean;
}

export class AccountStore {
  currency = "USD";
  info = {} as MobileOfficeAccountInfo;
  AccountStatus = {} as UserStatus;
  Login = "";
  Email = "";
  CurrencyDigits = "";
  Balance = 0;
  Credit = "";
  Margin = "";
  MarginFree = "";
  Profit = "";
  Storage = "";
  Equity = "";
  Name = "";
  FirstName = "";
  LastName = "";
  Leverage = "";
  BalancePrevDay = "";
  EquityPrevDay = "";
  // ----- all attributes bellow are not used on ui
  Commission = "";
  Group = "";
  get hasCrypto() {
    return String(this.Group).indexOf("NoCRY") === -1;
  }
  MarginLevel = "";
  MarginLeverage = "";
  Floating = "";
  MarginInitial = "";
  MarginMaintenance = "";
  CertSerialNumber = "";
  Rights = "";
  Registration = "";
  LastAccess = "";
  LastPassChange = "";
  LastIP = "";
  MiddleName = "";
  Company = "";
  Account = "";
  Country = "";
  Language = "";
  ClientID = "";
  City = "";
  State = "";
  ZipCode = "";
  Address = "";
  Phone = "";
  ID = "";
  Status = "";
  Comment = "";
  Color = "";
  Agent = "";
  InterestRate = "";
  BalancePrevMonth = "";
  EquityPrevMonth = "";
  TradeAccounts = "";
  TradingAccounts: TradingAccount[] | null = null;
  selectedTradingAccount: TradingAccount | null = null;
  creatingAccount = false;
  static FreshTokenRequest?: Promise<
    { access_token: string; refresh_token: string } | undefined
  > = undefined;

  get RiskLevel() {
    return Number(
      ((Number(this.Margin) / Number(this.Equity)) * 100).toFixed(2)
    );
  }

  get AccountState() {
    const state = this.info?.status_id;
    return USER_STATUSES_MAP[state];
  }

  changeAccountStatus = (status: StatusNames) => {
    const state = USER_STATUSES.find((r) => r.name === status);
    if (!state) return;
    return mobileApi
      .post("/syntellicore/set-user-status", { status_id: state.status_id })
      .then(() => {
        runInAction(() => {
          this.info.status_id = state.status_id;
          setCustomUserAttribute("KYC Status", state.title);
        });
      });
  };

  clear = () => {
    // reset all string properties
    Object.keys(this).forEach((k) => {
      const type = typeof this[k as keyof AccountStore];
      if ((k in this && type === "string") || type === "number") {
        // @ts-ignore
        this[k] = "";
      }
    });
    this.info = {} as MobileOfficeAccountInfo;
    this.selectedTradingAccount = null;
    this.TradingAccounts = [];
  };

  refresh = () => {
    return mobileApi
      .get<MobileOfficeAccountInfo>("/user")
      .then(({ data }) => {
        console.log("=====> Refreshed crm user info");
        logLogin(data);
        runInAction(() => {
          this.info = data;
        });
      })
      .then(() => (appUiStore.token ? this.getTradingAccounts() : null))
      .catch((e: any) => {
        // toastError(
        //   'Could not get account information, please check your internet and try again later.'
        // );
        captureException(e);
      });
  };

  get isLimited() {
    return accountStore.AccountState?.name !== ("ACTIVE" as any);
  }

  get isFundingBlocked() {
    return (
      ["LEVEL-1 Approved", "PENDINGKYCREVIEW", "ACTIVE"].includes(
        accountStore.AccountState?.name
      ) === false
    );
  }

  constructor() {
    makeAutoObservable(this, {
      refresh: false,
      update: false,
      fetchAndUpdateMetrics: false,
    });
  }

  async fetchAndUpdateMetrics() {
    api.get("/api/metrics").then((res) => {
      this.update(res.data);
    });
  }

  async getTradingAccounts() {
    return mobileApiV3.get("/trading/accounts").then((res) => {
      runInAction(() => {
        this.TradingAccounts = res.data;
        if (res.data?.length && !this.selectedTradingAccount)
          this.selectedTradingAccount = res.data[0];
      });
      return res.data;
    });
  }

  get isMT(): boolean {
    if (!this.selectedTradingAccount) {
      return !!this.info.mt_preference && this.info.mt_preference !== "MOBILE";
    }

    return checkIfMT(
      this.selectedTradingAccount?.mt_platform,
      this.selectedTradingAccount?.is_mobile_app
    );
  }

  update = (d: Tapi.Metrics | Tapi.User) => {
    runInAction(() => {
      Object.assign(this, d);
    });
  };

  getFreshToken = () =>
    mobileApi
      .get("/user/refresh")
      .then(({ data }) => {
        console.log("=====> Got new fresh session");
        appUiStore.processAccesstoken(data);
        appUiStore.fetchAndUpdateMTData();
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403)
          return;
        console.error(err.response);
        captureException(err);
      });

  selectTradingAccount = (loginId: string) => {
    const account = this.TradingAccounts?.find((t) => t.login === loginId);
    if (!account) return;
    this.selectedTradingAccount = account;
  };

  setCreatingAccount = (val: boolean) => {
    this.creatingAccount = val;
  };

  createTradingAccount = async (type: MT_TYPE) => {
    this.setCreatingAccount(true);
    try {
      await mobileApiV3.post<TradingAccount>("trading", {
        mt_type: type,
      });
      await this.getTradingAccounts();
    } catch (e) {
      console.log("createTradingAccount", e);
      this.setCreatingAccount(false);
      return false;
    }
    this.setCreatingAccount(false);
    return true;
  };

  get currentStep() {
    const { name } = this.AccountState;
    if (name === "Pending IDWISE") {
      return "pendingIDWISE";
    }
    if (name === "NODOCS") {
      return "missingDocuments";
    }
    if (name === "NEW" || name === "INCOMPLETE") {
      return "newUser";
    }
    if (name === "PENDING") {
      return "pending";
    }
    if (this.TradingAccounts?.length) {
      return "hasAccounts";
    }
    if (this.TradingAccounts?.length === 0) {
      return "firstDeposit";
    }
    return "none";
  }

  get isBlockedAccount() {
    return this.info?.lead_method?.toLowerCase() === "black";
  }

  get accountBalance() {
    const account = this.TradingAccounts?.find(
      (a) => a.login === this.selectedTradingAccount?.login
    );
    return !isNaN(Number(account?.balance)) ? Number(account?.balance) : 0;
  }
}

export const accountStore = new AccountStore();

export const getFreshToken = async (
  refreshToken?: string,
  refreshAll = true
) => {
  if (!refreshToken) {
    logout(false);
    return Promise.reject("No refresh token");
  }

  const decodedToken = decodeToken(refreshToken);
  if (decodedToken.exp * 1000 + 5000 < Date.now()) {
    logout(false);
    return Promise.reject("Token expired");
  }

  return refreshAppTokens(refreshToken)
    .then((data) => {
      console.log("=====> Got new fresh session");
      // Workaround in case use logout here
      if (!storage.getString("refresh_token"))
        throw Error("No stored refresh token");
      if (refreshAll) {
        appUiStore.processAccesstoken(data);
        accountStore.refresh();
        appUiStore.fetchAndUpdateMTData();
      }
      return data;
    })
    .catch((err) => {
      if (err.response?.status === 401 || err.response?.status === 403)
        return logout(false);
      console.error(err.response);
      captureException(err);

      Promise.reject(err);
    });
};
