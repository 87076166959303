import { Order } from "./Order";
import { ExchangeRate } from "./ExchangeRate";
import { api } from "../../api";
import { accountStore } from "../AccountStore";
import { OrderTicket } from "../NewOrderStore";
import { positionStore } from "../PositionStore";
import { serverClock } from "../ServerClock";
import { appUiStore } from "../AppUi";
import { ordersStore } from "../OrderStore";
import { Tick, tickStore } from "@/Lib/Prices";
import { priceEngine } from "@/Lib/PriceEngine";
import {
  makeAutoObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx";
import { DateTime } from "luxon";
import type { Position } from "./Position";
import type { Tapi } from "@/Lib/websocket";

// @TODO: add a way to delete lastNBids after 5 mins
// @FIXME: monkey patch .get and .set probably
export const lastNbids = observable.map<string, number[]>();

export class SymbolInfo {
  toString() {
    return this.symbol;
  }

  symbol: string;
  data: Tapi.Symbols;
  get tick(): Tick | undefined {
    return tickStore.data[this.symbol];
  }
  step = 0.01;
  min = 0.01;
  max = 50;

  flushPrices() {}

  get UnitDigits() {
    const x = +this.data?.VolumeStepExt / 100000000;
    if (!Number.isFinite(x)) return 0; // Handle non-finite numbers

    const str = x.toString();

    // Check if it's in scientific notation
    if (str.includes("e")) {
      const [base, exponent] = str.split("e").map(Number);
      return Math.max(0, -exponent); // Exponent tells how many places after decimal
    }

    // Otherwise, count digits after the decimal point
    const decimalPart = str.split(".")[1];
    return decimalPart ? decimalPart.length : 0;
  }

  // server time
  get dt() {
    if (!this.tick) return new Date();
    return new Date(this.tick.DateTime);
  }
  tradeTimes = [] as Array<{ Open: number; Close: number } | undefined>[];

  get DT() {
    return this.ShouldDelay ? this.delayedDT || this.dt : this.dt;
  }

  get Leveraged() {
    if (!this.data) {
      return false;
    }
    // --- forex of has no margin rate
    return (
      `${this.data?.CalcMode}` === "0" ||
      Number(this.data?.MarginInitialBuy) !== 1
    );
  }

  getLeverage(isBuy = false) {
    if (!this.data) {
      return 1;
    }

    const mode = Number(this.data?.CalcMode);
    const marginRate =
      Number(
        isBuy ? this.data?.MarginInitialBuy : this.data?.MarginInitialSell
      ) || 1;

    // --- CFD Leveraged or FX
    if (mode === 0 || mode === 4) {
      return 1 / ((1 / Number(accountStore.Leverage)) * marginRate);
    }

    return 1 / marginRate;
  }

  get Sector() {
    const s = Number(this.data?.Sector) as keyof typeof SECTORS;
    return this.data?.Sector ? SECTORS[s] || SECTORS["0"] : SECTORS["0"];
  }

  get Industry() {
    return this.data?.Industry;
  }

  get FriendlyName() {
    return this.data?.International || this.symbol || "--";
  }

  get UnitPrecision() {
    return String(
      (Number(this.data?.VolumeStepExt) / 100000000)
        .toFixed(10)
        .replace(/0*$/, "")
        .split(".")
        .pop()
    ).length;
  }

  delayedBid = 0;
  delayedAsk = 0;
  delayedLast = 0;
  // server time
  delayedDT = new Date("2021-08-15");
  delayedTickStore = [];
  promise: any = null;

  async fetchDelayedPrices() {
    if (this.promise) {
      return this.promise;
    }
    let start = Math.floor(Date.now() / 1000) - 900;
    this.promise = api
      .get<[date: number, b: number, a: number, l: number][]>(
        `/api/ticks/${this.symbol}/${start}/600`
      )
      .then(async ({ data: rows }) => {
        if (rows.length === 0) {
          return;
        }

        // @TODO: this is pure garbage approach.
        // timeout will cause memory leaks. re-fetching will cause duplicate thing. so many things can go wrong.
        if (rows && rows[0]) {
          runInAction(() => {
            this.delayedDT = new Date(rows[0][0]);
            this.bidDir = this.delayedBid > rows[0][2];
            this.delayedBid = rows[0][1];
            this.askDir = this.delayedAsk > rows[0][2];
            this.delayedAsk = rows[0][2];
            this.delayedLast = rows[0][3];
          });
        }
        start = Math.floor(Date.now()) - 15 * 60 * 1000;
        rows.forEach((row) =>
          row[0] - start > 0
            ? setTimeout(() => {
                runInAction(() => {
                  this.delayedDT = new Date(rows[0][0]);
                  this.delayedBid = row[1];
                  this.delayedAsk = row[2];
                  this.delayedLast = row[3];
                });
              }, row[0] - start)
            : null
        );

        // let now = new Date();
        // let timeStamp15Minago =
        //   now.getTime() - 900000 - now.getTimezoneOffset() * 60 * 1000;
        // console.log(
        //   `Band--------->${rows[0][0] - timeStamp15Minago} ~ ${
        //     rows[rows.length - 1][0] - timeStamp15Minago
        //   }`
        // );
        // const filterRow = rows.filter((n) => n[0] > timeStamp15Minago);
        // null;
        // for (let i = 0; i < rows.length; i++) {
        //   const row = rows[i];
        //   runInAction(() => {
        //     this.delayedDT = row[0];
        //     this.delayedBid = row[1];
        //     this.delayedAsk = row[2];
        //     this.delayedLast = row[3];
        //   });
        // }
      });
  }

  get Digits() {
    return Number(this.data?.Digits);
  }

  get isStock() {
    // ---- Exchange Mode
    return Number(this.data?.CalcMode) === 32;
  }

  get isCloseOnly() {
    return this.TradeMode === 3;
  }

  get Expired() {
    const start = Number(this.data.TimeStart);
    const end = Number(this.data.TimeExpiration);
    if (!start || !end) return false;
    const now = Math.floor(Date.now() / 1000);
    return now < start || now > end;
  }

  get TradeDisabled() {
    const mode = Number(this.data?.TradeMode);
    // -- disabled or close only and you don't have a position
    if (mode === 0 || (mode === 3 && !this.Position)) {
      return true;
    }
    return false;
  }

  get marketOpened() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { serverDate } = serverClock; // --- trigger refresh every time clock ticks... DOT NOT REMOVE UNUSED..
    const cyprusTime = DateTime.fromJSDate(new Date(), {
      zone: "Asia/Nicosia",
    });

    const minFromMidnight = cyprusTime.startOf("day");

    const times = this.tradeTimes[cyprusTime.weekday % 7];

    return times?.some((n) => {
      if (!n) return null;
      return (
        cyprusTime >=
          DateTime.fromSeconds(minFromMidnight.toUnixInteger() + n.Open * 60) &&
        cyprusTime <=
          DateTime.fromSeconds(minFromMidnight.toUnixInteger() + n.Close * 60)
      );
    });
  }

  private _lazyOrderTicket?: OrderTicket;

  get OrderTicket() {
    if (!this._lazyOrderTicket) {
      this._lazyOrderTicket = new OrderTicket(this);
    }

    return this._lazyOrderTicket;
  }

  get Position(): Position {
    return positionStore.bySymbol[this.symbol];
  }

  get Orders(): Order[] {
    return ordersStore.bySymbol[this.symbol];
  }

  roundUnits(n: string | number) {
    return Number(Number(n).toFixed(this.UnitDigits));
  }

  update = (props: Tapi.Symbols) => {
    if (!props) return;
    const contractSize = Number(props.ContractSize);
    this.step = (Number(props.VolumeStepExt) / 100000000) * contractSize;
    this.min = (Number(props.VolumeMinExt) / 100000000) * contractSize;
    this.max = (Number(props.VolumeMaxExt) / 100000000) * contractSize;

    if (typeof props.SessionsTrades === "string") {
      this.tradeTimes = (props.SessionsTrades as string).split("|").map((n) =>
        n.split("-").map((o) => {
          const x = o.split(":");
          if (x.length !== 2 || x[0] === x[1]) return undefined;
          const Open = Number(x[0]);
          const Close = Number(x[1]);
          return { Open, Close };
        })
      );
    } else if (Array.isArray(props.SessionsTrades)) {
      this.tradeTimes = props.SessionsTrades.map((session) => {
        return session.map((n) => {
          if (n.Open === n.Close) return undefined;
          const Open = Number(n.Open);
          const Close = Number(n.Close);

          return { Open, Close };
        });
      });
    }
  };
  watched = false;

  constructor(props: Tapi.Symbols) {
    this.symbol = props.Symbol;
    this.data = props;
    this.update(props);

    // if (!observe) return this; // used when we want helpers but dont need observability.. like in search

    makeAutoObservable(this, {
      update: false,
      data: false,
      toString: false,
    } as any);
    const symbol = String(this.symbol);
    onBecomeObserved(this, "tick", () => {
      priceEngine.subscribe(symbol);
    });

    onBecomeUnobserved(this, "tick", () => {
      priceEngine.unsubscribe(symbol);
    });

    if (this.ShouldDelay) {
      let int: any = null;
      onBecomeObserved(this, "delayedAsk", () => {
        clearInterval(int);
        this.fetchDelayedPrices();
        int = setInterval(() => this.fetchDelayedPrices(), 10 * 60 * 1000);
      });
      onBecomeUnobserved(this, "delayedAsk", () => {
        clearInterval(int);
        int = null;
      });
    }
  }

  get MarginExchangeRate() {
    return new ExchangeRate(this, "Margin");
  }

  get ProfitExchangeRate() {
    return new ExchangeRate(this, "Profit");
  }

  get ShouldDelay() {
    return this.data?.Exchange === "Delayed";
  }

  get isDWStock() {
    return this.data?.Exchange === "US" && !this.Leveraged;
  }

  get isEgyptStock() {
    return (
      this.data.Path.includes("Shares_Mena") &&
      this.data.Path.includes("CASE-Egypt")
    );
  }

  get canCloseEgyptStock() {
    const currentTime = new Date().getTime();
    const twoDaysMilliSeconds = 2 * 60 * 60 * 24 * 1000;

    if (!this.Position?.createdAt) return false;

    const positionLastTime = DateTime.fromMillis(
      this?.Position?.createdAt - appUiStore.TZ * 1000
    );

    return (
      !!positionLastTime &&
      currentTime - positionLastTime.toMillis() > twoDaysMilliSeconds
    );
  }

  get allowSell() {
    const tradeMode = this.TradeMode;
    // --- Trade disabled
    if (tradeMode === 0) {
      return false;
    }

    //  --- always allow to partial close
    if (this.Position?.isBuy) {
      return true;
    }

    //  --- short only
    if (tradeMode === 2) {
      return true;
    }

    // --- Trade disabled for closeonly mode
    if (tradeMode === 3) {
      return false;
    }

    return tradeMode === 4;
  }

  get TradeMode() {
    const tradeMode = Number(this.data?.TradeMode);
    return this.Expired ? 3 : tradeMode || 0;
  }

  get tradingDisabled() {
    return this.TradeMode === 0;
  }

  get allowBuy() {
    const tradeMode = this.TradeMode;
    // --- Trade disabled
    if (tradeMode === 0) {
      return false;
    }

    //  --- allow partial close if you have short
    if (this.Position?.isBuy === false) {
      return true;
    }

    //  --- long only
    if (tradeMode === 1) {
      return true;
    }

    // --- Trade disabled for closeonly mode
    // if (tradeMode === 3 || tradeMode === 2) {
    //   return false;
    // }

    return tradeMode === 4;
  }

  get Bid() {
    const bid = Number(
      Number(
        this.ShouldDelay && this.delayedBid ? this.delayedBid : this.tick?.bid
      ).toFixed(this.Digits)
    );
    return bid || 0;
  }

  get Ask() {
    const ask = Number(
      Number(
        this.ShouldDelay && this.delayedAsk ? this.delayedAsk : this.tick?.ask
      ).toFixed(this.Digits)
    );
    return ask || 0;
  }

  get DailyDirectionUp() {
    if (!this.tick) return false;
    return this.tick?.bid > this.tick?.closePrice;
  }

  get DailyDirectionColor(): "green" | "red" {
    return this.DailyDirectionUp ? "green" : "red";
  }

  getPercentageChange(price = 0) {
    if (!this.tick?.closePrice) return 0;
    const per =
      (Number(price - this.tick.closePrice) / this.tick.closePrice) * 100;
    return per ? Number(per.toFixed(2)) : 0;
  }

  get PercentageChange() {
    if (!this.tick?.closePrice) return 0;
    const per = (Number(this.PriceChange) / this.tick.closePrice) * 100;
    return per ? Number(per.toFixed(2)) : 0;
  }

  get tripleSwapDays() {
    const val = Number(this.data?.Swap3Day);
    return val === 7 ? 0 : val; // -- 0 = sunday
  }

  getSwap() {
    console.error("Not implemented");
    return 0;
  }

  get PriceChangeDigits() {
    return this.PriceChange.replace(/^-?[0.]*/, "").length;
  }

  get PriceChange() {
    if (!this.tick) return Number(0).toFixed(this.Digits);
    const price = this.tick.ltp || this.tick.bid;
    return (price - this.tick.closePrice).toFixed(this.Digits);
  }

  get MaxUnitsPerUser() {
    return (
      (Number(this.data?.VolumeLimitExt) / 100000000) *
      Number(this.data?.ContractSize)
    );
  }

  askDir = false;
  bidDir = false;
}

export const SECTORS = {
  0: "Undefined",
  1: "Basic materials",
  2: "Communication services",
  3: "Consumer cyclical",
  4: "Consumer defensive",
  5: "Energy",
  6: "Finance",
  7: "Healthcare",
  8: "Industrials",
  9: "Real estate",
  10: "Technology",
  11: "Utilities",
  12: "Currency",
  13: "Crypto currency",
  14: "Indices",
  15: "Commodities",
};
